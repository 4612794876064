<template>
  <div class="main">
    <div v-loading="listLoading" class="list">
      <div class="client-search">
        <div style="display: flex; justify-content: space-between">
          <el-select
            v-model="searchData.typeInput"
            class="search-select"
            size="mini"
            style="width: 110px"
          >
            <el-option label="UID" value="1"></el-option>
            <el-option label="昵称" value="2"></el-option>
            <el-option label="手机号" value="3"></el-option>
          </el-select>
          <el-input
            v-model="searchData.q"
            class="search-input"
            clearable
            placeholder="请输入ID或者昵称或手机号"
            size="mini"
          ></el-input>
        </div>
        <div style="display: flex">
          <el-select
            v-model="searchData.status"
            class="search-select"
            clearable
            placeholder="状态"
            size="mini"
            style="width: 50%"
          >
            <el-option label="服务中" value="1"></el-option>
            <el-option label="已结业" value="2"></el-option>
            <el-option label="已退费" value="3"></el-option>
          </el-select>
          <el-select
            v-model="searchData.tagIds"
            class="search-select"
            clearable
            filterable
            multiple
            placeholder="标签"
            size="mini"
            style="width: 50%"
          >
            <el-option
              v-for="item in tagList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display: flex">
          <el-select
            v-model="searchData.course"
            class="search-select"
            clearable
            filterable
            multiple
            placeholder="已购课程筛选"
            size="mini"
            style="width: 50%"
          >
            <el-option
              v-for="item in courseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="searchData.checkCourse"
            class="search-select"
            clearable
            filterable
            multiple
            placeholder="打卡课程筛选"
            size="mini"
            style="width: 50%"
          >
            <el-option
              v-for="item in checkCourseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display: flex; margin-bottom: 10px; align-items: center">
          <el-radio-group
            v-model="searchData.todayWorkStatus"
            size="mini"
            style="margin-right: 10px"
          >
            <el-radio-button :label="0">全部</el-radio-button>
            <el-radio-button :label="1">今日已交作业</el-radio-button>
            <el-radio-button :label="2">今日未交作业</el-radio-button>
          </el-radio-group>
        </div>
        <div style="display: flex; margin-bottom: 10px; align-items: center">
          <el-radio-group
            v-model="searchData.todayVisit"
            size="mini"
            style="margin-right: 10px"
          >
            <el-radio-button :label="false">全部</el-radio-button>
            <el-radio-button :label="true">今日待回访</el-radio-button>
          </el-radio-group>
          <el-button size="mini" type="primary" @click="search">搜索</el-button>
        </div>
      </div>
      <div v-if="list.length > 0" class="list-box">
        <div
          v-for="item in list"
          :key="item.id"
          :class="item.id === active ? 'active' : ''"
          class="list-item"
          @click="getUserInfo(item)"
        >
          <div class="top-box">
            <div class="item-info">
              <img
                v-if="item.avatar_url"
                :src="item.avatar_url"
                alt=""
                class="image"
              />
              <div v-else class="image"></div>
              <div class="id_name">
                <div>ID:{{ item.user_id }}</div>
                <div>{{ item.nickname }}</div>
              </div>
            </div>
            <div class="status">
              <div v-if="item.status === 1" class="state-up">服务中</div>
              <div v-else-if="item.status === 2" class="state-down">已结课</div>
              <div v-else-if="item.status === 3" class="state-red">已退费</div>
            </div>
          </div>
          <div class="bottom-box">创建时间: {{ item.created_at }}</div>
        </div>
      </div>
      <div v-else class="hint">暂无数据</div>

      <div class="block">
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          :total="total"
          background
          layout="prev, pager, next"
          small
          @current-change="setPage"
        >
        </el-pagination>
      </div>
    </div>
    <div v-loading="infoLoading || listLoading" class="info-information">
      <div class="info-top-box">
        <div class="user-info">
          <img
            v-if="infoData.avatar_url"
            :src="infoData.avatar_url"
            alt=""
            class="image"
          />
          <div v-else class="image"></div>
          <div class="id_name">
            <div>ID:{{ infoData.user_id }}</div>
            <div>用户名：{{ infoData.nickname }}</div>
          </div>
        </div>
      </div>
      <div class="padding-sm">
        <el-descriptions>
          <el-descriptions-item label="手机号">
              <el-input
                  style="width: 120px;margin-right: 10px"
                  placeholder="请输入内容"
                  v-model="userPhone"
                  clearable
                  :disabled="!isEditPhone">
              </el-input>
              <el-button v-if="!isEditPhone" size="mini" type="primary" @click="editUserPhone">编辑</el-button>
              <span v-else>
                <el-button size="mini" type="success" @click="savePhone">保存</el-button>
                <el-button size="mini"  @click="cancelPhone">取消</el-button>
              </span>
          </el-descriptions-item>
          <el-descriptions-item label="创建时间"
            >{{ infoData.created_at }}
          </el-descriptions-item>
          <el-descriptions-item label="班主任">
            <div>
              <el-tag
                v-for="(teacher, index) in infoData.teacher"
                :key="index"
                effect="plain"
                size="mini"
                style="margin: 5px"
                >{{ teacher }}
              </el-tag>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="状态">
            <div v-if="infoData.user_status === 1" class="state-up-v">正常</div>
            <div v-else-if="infoData.user_status === 2" class="state-down-v">
              限制登录
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="服务状态">
            <div v-if="infoData.service_status === 1" class="state-up-v">
              服务中
            </div>
            <div v-else-if="infoData.service_status === 2" class="state-down-v">
              已结课
            </div>
            <div v-else-if="infoData.service_status === 3" class="state-red-v">
              已退费
            </div>
            <el-button
              size="mini"
              style="margin-left: 20px"
              type="primary"
              @click="
                dialogVisible1 = true;
                status = infoData.service_status;
              "
              >更改服务
            </el-button>
          </el-descriptions-item>
          <el-descriptions-item label="学生标签">
            <div>
              <el-tag
                v-for="(tag, index) in infoData.tag"
                :key="index"
                closable
                size="mini"
                style="margin: 5px"
                @close="handleClose(tag)"
                >{{ tag.name }}
              </el-tag>
              <el-button
                size="mini"
                style="margin-left: 20px"
                type="primary"
                @click="initTagList"
                >添加标签
              </el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="转移状态"
            >{{ infoData.transfer_status ? "有转移" : "无转移" }}
          </el-descriptions-item>
          <el-descriptions-item label="学币"
            >{{ infoData.credit ? infoData.credit : 0 }}
            <el-button
              size="mini"
              style="margin-left: 20px"
              type="primary"
              @click="propDialogVisible = true"
              >赠送学币
            </el-button>
          </el-descriptions-item>
          <el-descriptions-item label="赠送礼物">
            <gift :user-list="[infoData]" field="user_id" type="3" />
          </el-descriptions-item>
          <el-descriptions-item label="是否允许电脑学习">
            <el-switch
                v-model="infoData.allow_pc"
                @change="setAllowPc"
                :active-value="1"
                :inactive-value="0"
                active-text="开启"
                inactive-text="关闭">
            </el-switch>
          </el-descriptions-item>
        </el-descriptions>

      </div>
      <div class="consult-information">
        <div class="table">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="已购服务" name="serve">
              <Serve
                :id="infoData.id"
                :load="activeName === 'serve'"
                style="padding: 10px 0"
              ></Serve>
            </el-tab-pane>
            <el-tab-pane label="报名信息" name="apply">
              <Apply
                :id="infoData.id"
                :load="activeName === 'apply'"
                style="padding: 10px 0"
              ></Apply>
            </el-tab-pane>

            <el-tab-pane label="学员动态" name="social">
              <Social
                :id="infoData.id"
                :load="activeName === 'social'"
                style="padding: 10px 0"
              ></Social>
            </el-tab-pane>

            <el-tab-pane label="学员作业" name="work">
              <Work
                :id="infoData.id"
                :load="activeName === 'work'"
                style="padding: 10px 0"
              ></Work>
            </el-tab-pane>

            <el-tab-pane label="学员问答" name="answers">
              <Answers
                :id="infoData.id"
                :load="activeName === 'answers'"
                style="padding: 10px 0"
              ></Answers>
            </el-tab-pane>

            <el-tab-pane label="退费记录" name="studentProfileRefund">
              <Student-Profile-Refund
                :id="infoData.id"
                :load="activeName === 'studentProfileRefund'"
                style="padding: 10px 0"
              ></Student-Profile-Refund>
            </el-tab-pane>

            <el-tab-pane label="转移记录" name="transfer">
              <Transfer
                :id="infoData.id"
                :load="activeName === 'transfer'"
                style="padding: 10px 0"
              ></Transfer>
            </el-tab-pane>

            <el-tab-pane label="学员近况" name="recently">
              <Recently
                :id="infoData.id"
                :load="activeName === 'recently'"
                style="padding: 10px 0"
              ></Recently>
            </el-tab-pane>

            <el-tab-pane label="学币记录" name="currency">
              <Currency
                :id="infoData.id"
                :load="activeName === 'currency'"
                style="padding: 10px 0"
              ></Currency>
            </el-tab-pane>
            <el-tab-pane label="设备管理" name="device">
              <Device
                  :id="infoData.user_id"
                  :load="activeName === 'device'"
                  style="padding: 10px 0"
              ></Device>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="propDialogVisible"
      append-to-body
      title="赠送学币"
      width="30%"
    >
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        赠送学币数量：
        <span style="width: 200px">
          <el-input-number
            v-model="num"
            :min="1"
            label="输入赠送的学币数量"
            size="mini"
          ></el-input-number>
        </span>
      </div>
      <span>
        <el-button size="mini" @click="propDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          :loading="btnLoad"
          size="mini"
          type="primary"
          @click="giveProp"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible1"
      append-to-body
      title="服务更改"
      width="30%"
    >
      <div>
        <el-select
          v-model="status"
          class="search-select"
          placeholder="状态"
          size="mini"
        >
          <el-option :value="1" label="服务中"></el-option>
          <el-option :value="2" label="已结业"></el-option>
          <el-option :value="3" label="已退费"></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="changeServe"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="tagDialogVisible"
      append-to-body
      title="学生标签"
      width="40%"
    >
      <div class="label-top">
        <div>已选标签:{{ infoData.tag?.length }}/{{ tagList?.length }}</div>
        <el-button size="mini" @click="addTagDialogVisible = true"
          >新增标签
        </el-button>
      </div>
      <div class="tag-table" style="height: 400px">
        <el-table
          ref="multipleTable"
          v-loading="tagLoading"
          :data="tagList"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="标签名称" prop="name"></el-table-column>
          <el-table-column label="操作" prop="name">
            <template slot-scope="scope">
              <span>
                <el-link type="primary" @click="editTags(scope.row)"
                  >修改</el-link
                > </span
              >-
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tagDialogVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="selectTag"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="addTagDialogVisible"
      append-to-body
      title="创建学生标签"
      width="30%"
    >
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        学生标签：
        <span style="width: 200px"
          ><el-input v-model="input" placeholder="请输入学生标签"></el-input
        ></span>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        标签排序：
        <span style="width: 200px">
          <el-input-number
            v-model="sort"
            :min="0"
            label="请输入排序"
            size="mini"
          ></el-input-number>
        </span>
      </div>
      <span>
        <el-button size="mini" @click="addTagDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="addTag">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-loading="editLoading"
      :visible.sync="editTagDialogVisible"
      append-to-body
      title="编辑学生标签"
      width="30%"
    >
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        学生标签：
        <span style="width: 200px"
          ><el-input v-model="input" placeholder="请输入学生标签"></el-input
        ></span>
      </div>
      <div style="display: flex; align-items: center; margin-bottom: 20px">
        标签排序：
        <span style="width: 200px">
          <el-input-number
            v-model="sort"
            :min="0"
            label="请输入排序"
            size="mini"
          ></el-input-number>
        </span>
      </div>
      <span>
        <el-button size="mini" @click="editTagDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          :loading="tagLoading"
          size="mini"
          type="primary"
          @click="editTag"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { config } from "@/js/config";
import Serve from "./components/serve/list.vue";
import StudentProfileRefund from "./components/studentProfileRefund/list.vue";
import Apply from "./components/apply/list.vue";
import Answers from "./components/answers/list.vue";
import Transfer from "./components/transfer/list.vue";
import Work from "./components/work/list.vue";
import Recently from "./components/recently/list.vue";
import Currency from "./components/currency/list.vue";
import Device from "./components/device/list.vue";
import Social from "./components/social/list.vue";
import gift from "@/components/gift/index.vue";

export default {
  components: {
    Serve,
    StudentProfileRefund,
    Apply,
    Answers,
    Transfer,
    Work,
    Recently,
    Currency,
    Device,
    Social,
    gift,
  },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
      userID: (state) => state.user.info.id,
    }),
  },
  data() {
    return {
      editTagDialogVisible: false,
      editLoading: false,
      tagLoading: false,
      addTagDialogVisible: false,
      formLoading: false,
      tagDialogVisible: false,
      dialogVisible1: false,
      active: 0,
      listLoading: false,
      activeName: "serve",
      searchData: {
        typeInput: "1",
        todayVisit: false,
        todayWorkStatus: 0,
      },
      courseList: [],
      checkCourseList: [],
      list: [],
      page: 1,
      pageSize: 10,
      total: 0,
      infoLoading: false,
      infoData: {},
      dialogVisible: false,
      form: {},
      searchType: 1,
      loading: false,
      studentName: "",
      users: [],
      notData: false,
      selectCurrentUser: {},
      propDialogVisible: false,
      num: 1,
      input: "",
      path: config.imageUrl,
      tagList: [],
      btnLoad: false,
      status: 1,
      sort: 0,
      id: 0,
      userPhone:'',
      isEditPhone:false,
    };
  },
  methods: {
    ...mapActions("customerCard", [
      "gainCustomer",
      "studentProfileList",
      "selectStudentTags",
      "studentProfileDetail",
      "editStudentProfile",
      "addStudentTag",
      "getStudentTag",
      "delUserStudentTag",
    ]),
    ...mapActions("user", ["setUserCredit","editPhone","editAllowPc"]),
    async handleClose(tag) {
      let form = {
        student_profile_id: this.infoData.id,
        id: tag.id,
      };
      const { res_info } = await this.delUserStudentTag(form);
      if (res_info != "ok") return;
      this.$message.success("删除成功");
      await this.getUserInfo(this.infoData);
    },
    async editTags(row) {
      this.editTagDialogVisible = true;
      this.editLoading = true;
      const { data } = await this.getStudentTag({ id: row.id });
      this.input = data.name;
      this.sort = data.sort;
      this.id = data.id;
      this.editLoading = false;
    },
    async editTag() {
      if (this.input === "") {
        return this.$message.error("标签不能为空");
      }
      let form = {
        id: this.id,
        name: this.input,
        sort: this.sort,
      };
      const { res_info } = await this.addStudentTag(form);
      if (res_info !== "ok") return;
      this.editTagDialogVisible = false;
      this.$message.success("编辑成功");
      this.tagLoading = true;
      const res = await this.selectStudentTags();
      this.tagList = res.data;
      this.initTagList();
      this.tagLoading = false;
    },
    async changeServe() {
      let form = {
        student_profile_id: this.infoData.id,
        status: this.status,
      };
      this.loading = true;
      const { res_info } = await this.editStudentProfile(form);
      this.loading = false;
      this.dialogVisible1 = false;
      if (res_info != "ok") return;
      this.$message.success("编辑成功");
      await this.getUserInfo(this.infoData);
    },
    async addTag() {
      if (this.input === "") {
        return this.$message.error("标签不能为空");
      }
      let form = {
        name: this.input,
        sort: this.sort,
      };

      const { res_info } = await this.addStudentTag(form);
      if (res_info !== "ok") return;
      this.addTagDialogVisible = false;
      this.$message.success("创建成功");
      this.tagLoading = true;
      const res = await this.selectStudentTags();
      this.tagList = res.data;
      this.initTagList();
      this.tagLoading = false;
    },
    async initTagList() {
      this.tagDialogVisible = true;
      let rows = await this.infoData.tag
        .filter((obj1) => {
          const obj2 = this.tagList.find((obj2) => obj2.id === obj1.id);
          return obj2 !== undefined;
        })
        .map((obj1) => {
          const obj2 = this.tagList.findIndex((obj2) => obj2.id === obj1.id);
          return obj2;
        });
      let msg = [];
      for (let index = 0; index < rows.length; index++) {
        const i = rows[index];
        msg.push(this.tagList[i]);
      }
      this.$refs.multipleTable.clearSelection();
      this.$nextTick(() => {
        msg.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      });
    },
    async selectTag() {
      let tags = this.$refs.multipleTable.selection.map((v) => v.id);
      let form = {
        student_profile_id: this.infoData.id,
        tags,
      };

      this.loading = true;
      const { res_info } = await this.editStudentProfile(form);
      this.loading = false;
      this.tagDialogVisible = false;
      if (res_info != "ok") return;
      this.$message.success("编辑成功");
      await this.getUserInfo(this.infoData);
    },
    editInfo() {
      this.$router.push("/studentNameCard/edit/" + this.infoData.id);
      localStorage.setItem("studentNameCardPage", this.page);
      localStorage.setItem("studentNameCardUserInfoId", this.infoData.id);
    },
    giveProp() {
      let msg = JSON.parse(JSON.stringify(this.infoData.id));
      let data = {
        userId: this.infoData.user_id,
        credit: this.num,
      };
      this.infoData.id = 0;
      this.btnLoad = true;
      this.setUserCredit(data)
        .then((res) => {
          console.log(res);
          this.btnLoad = false;
          this.$message.success("赠送成功！");
          this.propDialogVisible = false;
          this.infoData.id = msg;
          this.getUserInfo(this.infoData);
        })
        .catch((err) => {
          this.infoData.id = msg;
        });
    },
    async getUserInfo(row) {
      this.infoLoading = true;
      this.active = row.id;
      const { data } = await this.studentProfileDetail({
        student_profile_id: row.id,
      });
      console.log('------------')
      console.log(data)
      this.infoData = data;
      this.infoLoading = false;
      this.userPhone = data.phone
    },
    handleClick(key) {
      localStorage.setItem("studentNameCard", this.activeName);
    },
    source(type) {
      switch (type) {
        case 1:
          return "微信";
        case 2:
          return "抖音";
      }
    },
    async setAllowPc(e){
      await this.editAllowPc({
        uid:this.infoData.user_id,
        allowPc:e,
      })
      this.$message.success("设置成功")
    },
    async onSubmit() {
      this.loading = true;
      let form = {
        user_id: this.form.userId,
        id: this.infoData.id,
      };
      const { res_info } = await this.bindUser(form);
      this.dialogVisible = false;
      if (res_info !== "ok") return;
      this.$message.success("绑定成功");
      await this.getList();
      this.loading = false;
    },
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
          this.form.userId = item.id;
        }
      });
      this.form.user_id = id;
    },
    async gain() {
      const { res_info } = await this.gainCustomer();
      if (res_info !== "ok") return;
      this.$message.success("获取成功");
      await this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      try {
        this.listLoading = true;
        if (this.list.length === 0) {
          const res = await this.selectStudentTags();
          this.tagList = res.data;
        }

        let page = 1;
        if (localStorage.getItem("studentNameCardPage")) {
          console.log(parseInt(localStorage.getItem("studentNameCardPage")));
          page = parseInt(localStorage.getItem("studentNameCardPage"));
          localStorage.removeItem("studentNameCardPage");
        } else {
          page = this.page;
        }
        let form = {
          ...this.searchData,
          page,
          pageSize: this.pageSize,
        };
        if (form.typeInput === "1") {
          form.userId = form.q;
        } else {
          form.nickname = form.q;
        }
        const { data } = await this.studentProfileList(form);
        this.list = data.list;
        this.total = data.total;
        this.page = page;
        if (data.list.length > 0) {
          let obj = {};
          if (localStorage.getItem("studentNameCardUserInfoId")) {
            obj.id = parseInt(
              localStorage.getItem("studentNameCardUserInfoId")
            );
            localStorage.removeItem("studentNameCardUserInfoId");
          } else {
            obj = data.list[0];
          }
          await this.getUserInfo(obj);
        } else {
          this.infoData = {};
        }
        this.listLoading = false;
      } catch (err) {
        this.listLoading = false;
      }
    },
    //编辑手机号
    editUserPhone(){
      this.isEditPhone = true;
    },
    async savePhone(){
      console.log('编辑手机号')
      await this.editPhone({
        uid:this.infoData.user_id,
        phone:this.userPhone,
      })
      this.$message.success('修改成功！')
      this.isEditPhone = false;
    },
    cancelPhone(){
      this.isEditPhone = false;
      this.userPhone = this.infoData.phone
    }
  },
  mounted() {
    this.activeName =
      localStorage.getItem("studentNameCard") || this.activeName;
    if (localStorage.getItem("studentNameCardID")) {
      this.searchData.q = localStorage.getItem("studentNameCardID");
      localStorage.removeItem("studentNameCardID");
    }
    this.getList();
    console.log(window.innerHeight);
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  background: #f4f5f9;
  display: flex;
}

.list {
  background: #fff;
  width: 320px;
  height: 100%;
  overflow: auto;
  margin-right: 10px;
  padding: 10px 10px 40px 10px;
  position: relative;
}

.hint {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-information {
  width: calc(100% - 320px);
  height: 100%;
  overflow: auto;
  background: #fff;
  padding: 15px;
}

.search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.search-select {
  width: 30%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.search-input {
  width: 100%;
  margin-bottom: 10px;
}

.list-box {
  height: 600px;
  overflow: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.list-box::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.list-item {
  width: 300px;
  margin: auto;
  padding: 15px 10px;
  border-radius: 8px;
  background: #fcfcfc;
  margin-bottom: 10px;
  cursor: pointer;
}

.list-item:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.block {
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
}

.item-info {
  display: flex;
  align-items: center;
}

.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: #eee;
}

.id_name {
  line-height: 30px;
}

.top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  margin-right: 20px;
}

.bottom-box {
  margin-top: 20px;
}

.state-red {
  color: red !important;
}

.active {
  background-color: #aad4ff;
}

.binding {
  margin: 0 60px;
}

.user-info {
  padding: 10px;
  display: flex;
  align-items: center;
}

.info-top-box {
  display: flex;
  justify-content: space-between;
}

.material {
  padding: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
}

.consult-information {
  margin-top: 10px;
}

.consult-information-title {
  margin: 30px 0;
}

.consult-information-add {
  width: 100%;
  text-align: right;
  /* margin-bottom: 10px; */
}

.consult-information-title span {
  padding: 13px 30px;
  border-radius: 10px 10px 0px 0px;
  background: #e0e0e0;
}

.material-left {
  width: 30%;
}

.material-left div {
  padding: 10px 0;
}

.state-up-v:before {
  top: 8px;
}

.label-top {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.tag-table {
  overflow: auto;
}
</style>