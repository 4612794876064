<template>
  <div>
    <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
      <el-table-column label="ID" prop="id" width="80"> </el-table-column>
      <el-table-column label="昵称" prop="nickname" width="100">
      </el-table-column>
      <el-table-column label="类型" prop="type" width="80">
        <template slot-scope="scope">
          {{ source(scope.row.type) }}
        </template></el-table-column
      >
      <el-table-column label="课程名称" prop="course" width="100">
      </el-table-column>
      <el-table-column label="收款方式" prop="pay_type" width="100">
        <template slot-scope="scope">
          {{ getPayType(scope.row.pay_type) }}
        </template>
      </el-table-column>

      <el-table-column label="收款金额" prop="price" width="100">
      </el-table-column>
      <el-table-column label="地址" prop="address" width="100">
      </el-table-column>

      <el-table-column label="状态" prop="pay_type" width="80">
        <template slot-scope="scope">
          {{ scope.row.status === 0 ? "未处理" : "已处理" }}
        </template>
      </el-table-column>

      <el-table-column label="核实状态" prop="pay_type" width="100">
        <template slot-scope="scope">
          {{ scope.row.check === 0 ? "未核实" : "已核实" }}
        </template>
      </el-table-column>

      <el-table-column label="是否需要邮寄" prop="pay_type" width="100">
        <template slot-scope="scope">
          {{ scope.row.is_address === 0 ? "否" : "是" }}
        </template>
      </el-table-column>

      <el-table-column label="图片/视频" width="100">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic[0]"
            :preview-src-list="scope.row.pic"
            :src="scope.row.pic[0]"
            style="width: 100px; height: 100px"
          >
          </el-image>
        </template>
      </el-table-column>

      <el-table-column label="地区" prop="area" width="100"> </el-table-column>
      <el-table-column label="年级" prop="grade" width="100"> </el-table-column>

      <el-table-column label="是否需要开通课程" prop="pay_type" width="100">
        <template slot-scope="scope">
          {{ scope.row.has_course === 0 ? "不需要" : "需要" }}
        </template>
      </el-table-column>

      <el-table-column label="课程状态" prop="pay_type" width="100">
        <template slot-scope="scope">
          {{ scope.row.course_status === 0 ? "未开通" : "已开通" }}
        </template>
      </el-table-column>

      <el-table-column label="手机号" prop="phone" width="100">
      </el-table-column>
      <el-table-column label="邮箱" prop="email" width="100"> </el-table-column>
      <el-table-column label="收件人" prop="user_name" width="100">
      </el-table-column>
      <el-table-column label="加粉日期" prop="add_at" width="100">
      </el-table-column>
      <el-table-column label="业绩归属" width="100">
        <template slot-scope="scope">
          {{ scope.row.sale_consultant_name }} / {{ scope.row.wechat }}
        </template>
      </el-table-column>
      <el-table-column label="订单号" prop="sn" width="100"> </el-table-column>
      <el-table-column label="支付单号" prop="pay_sn" width="100">
      </el-table-column>
      <el-table-column label="支付金额" prop="amount" width="100">
      </el-table-column>
      <el-table-column label="支付时间" prop="pay_at" width="100">
      </el-table-column>
      <el-table-column label="备注" prop="remark" width="100">
      </el-table-column>
    </el-table>
    <div class="table-batch">
      <span class="fl-r">
        <el-pagination
          :current-page="page"
          :page-size="pageSize"
          :page-sizes="[10, 20]"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          small
          @size-change="setPageSize"
          @current-change="setPage"
        >
        </el-pagination>
      </span>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {config} from "@/js/config";

export default {
  name: "list",
  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.tableList = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      infoData: {},
      tableLoading: false,
      tableList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      path: config.imageUrl,
    };
  },
  methods: {
    ...mapActions("customerCard", ["listStudentProfileSale"]),
    getPayType(type) {
      //1 微信 2 微信对公 3 支付宝 4 短书
      switch (type) {
        case 1:
          return "微信";
        case 2:
          return "微信对公";
        case 3:
          return "支付宝";
        case 4:
          return "短书";
      }
    },
    source(type) {
      switch (type) {
        case 1:
          return "新签";
        case 2:
          return "退款";
        case 3:
          return "转介绍";
        case 4:
          return "扩科目";
        case 5:
          return "续费";
        case 6:
          return "百度";
        case 7:
          return "头条";
      }
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.tableLoading = true;
      let form = {
        student_profile_id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.listStudentProfileSale(form);
      this.tableList = data.list.map(v=>{
        if(v.pic[0]){
          v.pic = v.pic.map((v) => this.path + v);
        }
        return v
      });
      this.total = data.total;
      this.tableLoading = false;
    },
  },
};
</script>

<style scoped></style>
