<template>
  <div>
    <el-table :data="list" v-loading="loading" style="width: 100%">
      <el-table-column label="ID" property="id" width="120px"></el-table-column>
      <el-table-column label="设备ID" property="device_id"></el-table-column>
      <el-table-column
          label="设备型号"
          property="device_model"
      ></el-table-column>
      <el-table-column label="绑定时间" property="created_at"></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <span>
            <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                title="解绑后将不可恢复，确定解绑吗？"
                @confirm="unbind(scope.row.id)"
            >
              <el-link slot="reference" type="primary">解绑</el-link>
            </el-popconfirm>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "device-list",

  props: {
    id: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  watch: {
    id(newValue) {
      if (this.load && newValue) {
        this.getList();
      } else {
        this.list = [];
      }
    },
    load(newValue) {
      if (this.load && this.id) {
        this.getList();
      }
    },
  },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  methods: {
    ...mapActions("user", ["deviceList", "unbindDevice"]),
    async getList() {
      try {
        this.loading = true;
        const { ret, data } = await this.deviceList({ id: this.id });
        if (ret === 0) {
          this.list = data;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    // 解绑
    async unbind(id) {
      const { ret } = await this.unbindDevice({ id: id });
      if (ret === 0) {
        this.$message.success("解绑成功");
        this.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>